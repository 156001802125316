/* Colors */
:root {
  --scout-blue: #006ddf; /* Traditional Scout blue color */
  --scout-purple: #4b0082; /* Traditional Scout purple color */
  --scout-yellow: #ffcc29; /* Traditional Scout yellow color */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 /* Add some basic CSS for styling */
 body {
  font-family: Arial, sans-serif;
}
body,
html {
  height: 100%;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
}
* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column-left {
  float: left;
  width: 80%;
  padding: 10px;
  padding-top: 0px;
}
.column-right {
  width: 20%;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  right: 0;
  position: absolute;
  border-left: 1px solid #000;
  height: 100%;
}

/* Clear floats after the columns */
.row {
  height: 100%;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
ul {
  list-style-type: none;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  width: 100%;
  border: 1px solid #ccc;
}
.product-container {
  display: none;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
@media only screen and (min-width: 480px) {
  .product-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    width: calc(20% - 10px); /* Adjust width to fit 4 cards in a row */
    margin: 10px 0; /* Adjust margin to create space between rows */
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .product-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    width: calc(50% - 5px); /* Adjust width to fit s cards in a row */
    margin: 10px 0; /* Adjust margin to create space between rows */
    text-align: center;
  }
}

.product-card img {
  width: auto;
  height: auto;
  max-height: 140px;
  max-width: 100%;
}
#cart-items {
  padding-left: 0;
}
.product-card h3 {
  margin-top: 10px;
  font-size: 1.5em;
}

.product-card p {
  font-size: 1em;
}
#card {
  width: 50%;
  float: left;
}
#cash {
  width: 50%;
  float: left;
}
.button-container {
  width: 100%;
  position: absolute;
  bottom: 10px;
  margin: -10px;
}

.button-card {
  width: calc(50% - 10px);
  padding: 15px;
  text-align: center;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.button-cash {
  width: calc(50% - 10px);
  padding: 15px;
  text-align: center;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 5px;
}
.button:hover {
  background-color: #2980b9;
}
.cart-table {
  border-collapse: collapse; /* Add a border-collapse style to the table */
  width: 100%;
  margin-top: 10px;
}

.cart-table th, .cart-table td {
  border: 1px solid #ddd; /* Add border styles to table headers and data cells */
  padding: 8px;
  text-align: left;
}

.cart-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Add background color to even rows for better readability */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* Calculator.css */
.calculator {
  width: 75vw;
  height: 75vh;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
}

.screen {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: right;
  font-size: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input {
  color: #333;
}

.result {
  color: #777;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.calcbutton {
  font-size: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
}

.calctd{
  width:33%
}

button:hover {
  background-color: #ddd;
}

.category-button{
  padding: 10px;
}
.tab-button {
  background-color: var(--scout-blue);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Arial', sans-serif; /* Use a Scout-like font if available */
  text-transform: uppercase;
}

.tab-button:hover {
  background-color: #fff;
  color: var(--scout-blue);
}

.tab-button.active {
  background-color: var(--scout-yellow);
}
.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}